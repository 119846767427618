import React from 'react'
import {Link} from 'react-router-dom'

const Navbar = () => {
    return (
        <nav className='w-screen h-[60px] flex bg-dark shadow-md px-8'>
            <div className="logo p-4 w-[40%]">
                <h1 className='text-red-500 text-xl font-bold'>FILMASU</h1>
            </div>
            <div className="hamburger sm:hidden h-full w-full justify-center items-end gap-[5px] flex flex-col pr-8">
                <span className='h-[3px] w-[30px] bg-white'></span>
                <span className='h-[3px] w-[35px] bg-white'></span>
                <span className='h-[3px] w-[40px] bg-white'></span>
            </div>
            <div className="right hidden sm:flex w-full">
                <div className="navlist w-[80%] lg:w-[85%] text-white flex items-center justify-end text-sm">
                    <Link className="px-4 py-4 relative after:content-[''] after:bottom-0 after:h-[2px] hover:after:w-full after:w-0 after:absolute after:bg-red-600 flex flex-col justify-center items-center after:duration-200" to="/">Daftar Film</Link>
                    <Link className="px-4 py-4 relative after:content-[''] after:bottom-0 after:h-[2px] hover:after:w-full after:w-0 after:absolute after:bg-red-600 flex flex-col justify-center items-center after:duration-200" to="/">Kartun</Link>
                    <Link className="px-4 py-4 relative after:content-[''] after:bottom-0 after:h-[2px] hover:after:w-full after:w-0 after:absolute after:bg-red-600 flex flex-col justify-center items-center after:duration-200" to="/">Action</Link>
                </div> 
                <div className="button w-[20%] lg:w-[15%] text-white flex items-center justify-center">
                    <Link className="hover:bg-red-600 border-2 border-solid border-red-600 duration-200 px-5 text-sm py-2 rounded" to='/'>Sign In</Link>
                </div>
            </div>
        </nav>
    )
}

export default Navbar