import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper'
import trailer from '../video/trailer.mp4'
import harry from '../Images/thumbnail.jpg'
import Rating from '../Components/Rating'
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import left from '../Images/left.png'
import right from '../Images/right.png'



import 'swiper/css';
import "swiper/css/navigation";


const Home = () => {
    const swiperRef = useRef(null);
    return (
        <>
            <Navbar />
            <div className='w-screen h-screen sm:h-[80vh] lg:h-[95vh] bg-dark font-moonserrat'>

                <div className="h-full w-full">
                    <div className="top h-[65%] sm:h-[60%] lg:h-[55%] w-full relative">
                        <div className="trailer-wrapper h-full w-full">
                            <div className="trailer h-full w-full">
                                <video className='object-cover h-full w-full' src={trailer} autoPlay loop muted></video>
                            </div>
                            <div className="absolute top-0 h-full w-full lg:flex lg:justify-center lg:items-center">
                                <div className="absolute top-0 h-full w-full">
                                    <div className="absolute left-0 z-10 hidden lg:flex pl-8 h-full  items-center" onClick={() => swiperRef.current.swiper.slidePrev()}>
                                        <img src={left} alt="left" />
                                    </div>
                                    <div className="absolute right-0 z-10 pr-8 hidden lg:flex h-full items-center" onClick={() => swiperRef.current.swiper.slideNext()}>
                                        <img src={right} alt="right" />
                                    </div>
                                    <Swiper
                                        ref={swiperRef}
                                        autoplay={{
                                            delay: 3000,
                                            disableOnInteraction: false,
                                        }}
                                        spaceBetween={30}
                                        loop={true}
                                        modules={[Autoplay, Navigation]}
                                        className="content-wrapper z-10 absolute top-0 h-full w-full ">
                                        <SwiperSlide className="lg:flex lg:flex-row-reverse group">
                                            <div className="wrapper w-full h-full lg:flex lg:flex-row-reverse lg:mx-[20vh] lg:my-4 2xl:my-8 lg:bg-white/25 lg:backdrop-blur-md lg:h-[90%] lg:rounded-xl">
                                                <div className="left-content h-[50%] lg:h-full lg:w-[37%] flex justify-center items-center lg:justify-center ">
                                                    <div className="img-wrapper w-[140px] sm:w-[160px] lg:w-[210px] 2xl:w-[250px]">
                                                        <img className='rounded-md focus:border-2 focus:border-solid focus:border-red-600' src={harry} alt="thumbnail" />
                                                    </div>
                                                </div>
                                                <div className="right-content h-[50%] lg:w-[63%] lg:h-full lg:flex lg:flex-col lg:items-center lg:justify-center ">
                                                    <div className="article h-full lg:w-[80%] w-full flex flex-col items-center lg:justify-center lg:m-8  lg:p-8">
                                                        <h1 className='text-white text-2xl font-semibold lg:text-start pb-1 lg:self-start lg:text-4xl '>Harry Potter</h1>
                                                        <p className='text-white text-xs px-6 w-[400px] lg:w-full text-center lg:text-start lg:self-start lg:text-sm lg:px-0 font-extralight'>Diselamatkan dari bibi & paman yang terlalu mengabaikannya, seorang bocah dengan garis takdir besar membuktikan kemampuannya saat ia mulai memasuki Sekolah Sihir Hogwarts.</p>
                                                        <div className="wrapper p-2 lg:p-0 h-full w-full lg:h-[40%] items-center lg:flex">
                                                            <div className="rating lg:h-full flex justify-center items-center lg:w-3/6 lg:justify-start lg:items-start lg:pt-6">
                                                                <Rating />
                                                                <p className='text-white pl-2'>5/5</p>
                                                            </div>
                                                            <div className="w-full h-full flex items-start justify-center pt-4 lg:w-3/6 lg:justify-end">
                                                                <Link to="" className='text-white border-2 border-solid border-red-600 hover:bg-red-600 px-8 py-3 mb-4 rounded duration-200'>Nonton</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom h-[35%] sm:h-[40%] lg:h-[45%] w-full group flex flex-col justify-center items-center px-8">
                        <div className="wrapper flex w-full h-[20%] lg:h-[10%] flex-col justify-end">
                            <div className="title-wrapper w-full px-4 flex flex-col justify-end items-start">
                                <h1 className='text-white text-xl sm:text-2xl font-semibold group-hover:w-[170px]'>Rekomendasi</h1>
                                <hr className='bg-red-600 w-[10%] sm:w-[5%] h-[3px] sm:group-hover:w-[25%] self-start border-none mt-2 group-hover:w-[40%] lg:group-hover:w-[15%] duration-500 ease-in-out' />
                            </div>
                        </div>
                        <div className="content-wrapper w-full h-[80%] flex items-center justify center px-2 lg:pb-2">
                            <div className="cards w-full flex flex-col justify-center gap-1 py-2 ">
                                <div className="more w-full flex justify-end items-end p-1 lg:px-8">
                                    <Link className=' text-white text-xs' to='/'>Lihat lebih banyak</Link>
                                </div>
                                <div className="wrapper flex h-full">
                                    <Swiper
                                        breakpoints={{
                                            // when window width is >= 256px
                                            256: {
                                                slidesPerView: 3,
                                            },
                                            // when window width is >= 640px
                                            640: {
                                                slidesPerView: 3,
                                            },
                                            // when window width is >= 768px
                                            768: {
                                                slidesPerView: 4,
                                                initialSlide: 2,
                                            },
                                            1024: {
                                                slidesPerView: 7,
                                                initialSlide: 2,
                                                centeredSlides: true,
                                            }
                                        }}
                                        centeredSlides={true}
                                        loop={true}
                                        spaceBetween={30}
                                        initialSlide={1}>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-h-screen w-screen border-none outline-none bg-dark px-8">
                    <div className="bottom h-[33.3%] w-full group flex flex-col justify-center items-center py-4">
                        <div className="wrapper flex w-full h-[20%] flex-col">
                            <div className="title-wrapper h-full w-full px-4 flex flex-col justify-end items-start">
                                <h1 className='text-white text-xl sm:text-2xl font-semibold group-hover:w-[170px]'>Film Kartun</h1>
                                <hr className='bg-red-600 w-[10%] sm:w-[5%] h-[3px] self-start border-none mt-2 group-hover:w-[35%] sm:group-hover:w-[20%] lg:group-hover:w-[15%] duration-500 ease-in-out' />
                            </div>
                        </div>
                        <div className="content-wrapper w-full h-[80%] flex items-center justify center px-2">
                            <div className="cards w-full flex flex-col justify-center gap-1  ">
                                <div className="more w-full flex justify-end items-end p-1 lg:px-8">
                                    <Link className=' text-white text-xs' to='/'>Lihat lebih banyak</Link>
                                </div>
                                <div className="wrapper flex h-full">
                                    <Swiper
                                        breakpoints={{
                                            // when window width is >= 256px
                                            256: {
                                                slidesPerView: 3,
                                            },
                                            // when window width is >= 640px
                                            640: {
                                                slidesPerView: 3,
                                            },
                                            // when window width is >= 768px
                                            768: {
                                                slidesPerView: 4,
                                                initialSlide: 2,
                                            },
                                            1024: {
                                                slidesPerView: 7,
                                                initialSlide: 2,
                                                centeredSlides: true,
                                            }
                                        }}
                                        centeredSlides={true}
                                        loop={true}
                                        spaceBetween={30}
                                        initialSlide={1}>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom h-[33.3%] w-full group flex flex-col justify-center items-center py-4 ">
                        <div className="wrapper flex w-full h-[20%] flex-col">
                            <div className="title-wrapper h-full w-full px-4 flex flex-col justify-end items-start">
                                <h1 className='text-white text-xl sm:text-2xl font-semibold group-hover:w-[170px]'>Film Action</h1>
                                <hr className='bg-red-600 w-[10%] sm:w-[5%] h-[3px] self-start border-none mt-2 group-hover:w-[35%] sm:group-hover:w-[20%] lg:group-hover:w-[15%] duration-500 ease-in-out' />
                            </div>
                        </div>
                        <div className="content-wrapper w-full h-[80%] flex items-center justify center px-2">
                            <div className="cards w-full flex flex-col justify-center gap-1  ">
                                <div className="more w-full flex justify-end items-end p-1 lg:px-8">
                                    <Link className=' text-white text-xs' to='/'>Lihat lebih banyak</Link>
                                </div>
                                <div className="wrapper flex h-full">
                                    <Swiper
                                        breakpoints={{
                                            // when window width is >= 256px
                                            256: {
                                                slidesPerView: 3,
                                            },
                                            // when window width is >= 640px
                                            640: {
                                                slidesPerView: 3,
                                            },
                                            // when window width is >= 768px
                                            768: {
                                                slidesPerView: 4,
                                                initialSlide: 2,
                                            },
                                            1024: {
                                                slidesPerView: 7,
                                                initialSlide: 2,
                                                centeredSlides: true,
                                            }
                                        }}
                                        centeredSlides={true}
                                        loop={true}
                                        spaceBetween={30}
                                        initialSlide={1}>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom h-[33.3%] w-full group flex flex-col justify-center items-center py-4 ">
                        <div className="wrapper flex w-full h-[20%] flex-col">
                            <div className="title-wrapper h-full w-full px-4 flex flex-col justify-end items-start">
                                <h1 className='text-white text-xl sm:text-2xl font-semibold group-hover:w-[170px]'>Film Drama</h1>
                                <hr className='bg-red-600 w-[10%] sm:w-[5%] h-[3px] self-start border-none mt-2 group-hover:w-[35%] sm:group-hover:w-[20%] lg:group-hover:w-[15%] duration-500 ease-in-out' />
                            </div>
                        </div>
                        <div className="content-wrapper w-full h-[80%] flex items-center justify center px-2">
                            <div className="cards w-full flex flex-col justify-center gap-1  ">
                                <div className="more w-full flex justify-end items-end p-1 lg:px-8">
                                    <Link className=' text-white text-xs' to='/'>Lihat lebih banyak</Link>
                                </div>
                                <div className="wrapper flex h-full">
                                    <Swiper
                                        breakpoints={{
                                            // when window width is >= 256px
                                            256: {
                                                slidesPerView: 3,
                                            },
                                            // when window width is >= 640px
                                            640: {
                                                slidesPerView: 3,
                                            },
                                            // when window width is >= 768px
                                            768: {
                                                slidesPerView: 4,
                                                initialSlide: 2,
                                            },
                                            1024: {
                                                slidesPerView: 7,
                                                initialSlide: 2,
                                                centeredSlides: true,
                                            }
                                        }}
                                        centeredSlides={true}
                                        loop={true}
                                        spaceBetween={30}
                                        initialSlide={1}>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="card w-[100px]">
                                            <div className="img">
                                                <img className="rounded-md" width={160} src={harry} alt="" />
                                            </div>
                                            <div className="article flex items-center justify-center text-white">
                                                <h1 className='text-md'>Harry Potter</h1>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </div>


        </>
    )
}

export default Home